@import '../app.scss';

div.modal {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: #00000075;
	z-index: 15;

	> .modal-box {
		width: 50%;
		padding: 15px;
		margin: 0 auto;
		background-color: #fff;
		margin-top: 150px;
		border-radius: 15px;
		@include tablette {
			width: 75%;
			margin-top: 75px;
		}

		> .modal-header {
			position: relative;

			> h3 {
				padding: 0;
				margin: 0;
				font-size: 25px;
				color: $primary-color;
				font-weight: bold;
				@include mobile {
					font-size: 18px;
				}
			}

			> .close {
				width: 50px;
				height: 50px;
				background-color: $primary-color;
				border-radius: 100%;
				text-align: center;
				position: absolute;
				top: -25px;
				right: -25px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				cursor: pointer;
				@include mobile {
					width: 30px;
					height: 30px;
				}

				> i {
					color: $tertiary-color;
					font-size: 25px;
					@include mobile {
						font-size: 18px;
					}
				}
			}
		}

		> .modal-body {
			overflow: auto;
			max-height: 500px;
			@include mobile {
				max-height: 600px;
				font-size: 14px;
			}
		}
	}
}
