@import '../app.scss';

section#accueil {
  @include tablette {
    margin-top: 90px;
  }
  > section.b-primary {
    padding-top: 80px;
    padding-bottom: 145px;
    @include mobile {
      padding-bottom: 40px;
    }
    > div {
      display: flex;
      @include mobile {
        display: block;
      }
      div.half {
        @include mobile {
          width: 100%;
        }
        > h1 {
          padding: 0;
          margin: 0;
          font-size: 50px;
          font-family: 'Playfair Display';
          margin-bottom: 50px;
          @include tablette {
            font-size: 47px;
          }
          @include mobile {
            font-size: 30px;
            margin-bottom: 30px;
            text-align: center;
          }
        }

        .button {
          transition: ease-in-out 0.2s;
          &:hover {
            background-color: #fff;
            color: $secondary-color;
          }
          @include mobile {
            font-size: 13px;
            position: relative;
            z-index: 2;
            width: 100%;
            box-sizing: border-box;
          }
        }
        > img {
          max-width: 100%;
          float: right;
          @include tablette {
            max-width: 100%;
          }
          @include mobile {
            margin-top: -320px;
            opacity: 0.3;
          }
        }
      }
    }
  }
}
