@import '../app.scss';

div.details {
	display: flex;
	@include mobile {
		display: block;
	}

	> div {
		width: 50%;
		padding: 10px;
		text-align: center;
		background: $primary-color;
		margin: 10px;
		border-radius: 15px;
		color: #fff;
		box-sizing: border-box;
		border: solid 3px $primary-color;
		transition: ease-in-out 0.2s;
		cursor: pointer;
		&:hover {
			background: #fff;
			color: $primary-color;
		}
		@include mobile {
			width: 100%;
			margin: 0;
			margin-bottom: 10px;
			font-size: 13px;
		}
	}
}
