// Polices d'écriture
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;800&display=swap');
// Variables
// Responsive
$breakpoints: (
  mobile: 767px,
  tablette: 992px,
);

// Couleurs
$primary-color: #e6dbc9;
$secondary-color: #c8e6c9;
$tertiary-color: #707070;
body {
  margin: 0;
  color: $tertiary-color;
}
html {
  scroll-behavior: smooth;
}
* {
  font-family: 'Poppins';
  font-weight: 400;
  outline: none;
  text-decoration: none;
}

a {
  color: $tertiary-color;
}

// Mixins
// Responsive
@mixin mobile {
  @media only screen and (max-width: map-get($breakpoints, mobile)) {
    @content;
  }
}
@mixin tablette {
  @media only screen and (max-width: map-get($breakpoints, tablette)) {
    @content;
  }
}

// Responsive
.content {
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  box-sizing: border-box;
  max-width: 1250px;
  @include mobile {
    max-width: 500px;
  }
  @include tablette {
    max-width: 900px;
  }
}
div.half {
  width: 50%;
  box-sizing: border-box;
  display: inline-block;
}

div.quarter {
  width: 75%;
  box-sizing: border-box;
  display: inline-block;
}
.content-min {
  padding-right: 120px;
  padding-left: 120px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1150px;
  box-sizing: border-box;

  @include mobile {
    max-width: 500px;
  }
  @include tablette {
    max-width: 800px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.f-right {
  float: right;
}
.img-cabinet {
  display: inline-block;
  min-width: 300px;
  min-height: 300px;
  background: red;
  background-size: 100%;
  background-position: center;
  border-radius: 100%;
  text-align: left;
  border: solid 10px #fff;
  filter: contrast(90%);
  transition: ease-in-out 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  &#un {
    background: url('../images/cabinet-1.JPG');
    background-size: 100%;
    background-position: center;
  }
  &#deux {
    background: url('../images/cabinet-2.JPG');
    background-size: 140%;
    background-position: right;
  }
}
.t- {
  &white {
    color: #fff;
  }
  &primary {
    color: $primary-color;
  }
  &secondary {
    color: $secondary-color;
  }
  &tertiary {
    color: $tertiary-color;
  }
}

.b- {
  &primary {
    background-color: $primary-color;
  }
  &secondary {
    background-color: $secondary-color;
  }
  &tertiary {
    background-color: $tertiary-color;
  }
}

.button {
  padding: 15px;
  border-radius: 500px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  padding-left: 50px;
  padding-right: 50px;
  cursor: pointer;
}

section.t {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  box-sizing: border-box;
  @include mobile {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  h2 {
    font-size: 30px;
    font-weight: 600;
    @include mobile {
      font-size: 20px;
    }
  }

  p {
    font-weight: 30px;
    font-weight: normal;
    line-height: 35px;
    @include mobile {
      font-size: 14px;
    }
  }
}

ul.l {
  list-style: none;
  line-height: 35px;
  padding-left: 0;
  @include mobile {
    font-size: 14px;
  }
  &.right li::after {
    content: '\f053';
    font-family: FontAwesome;
    display: inline-block;
    margin-left: 0;
    width: 1.3em;
  }
  &.left li::before {
    content: '\f054';
    font-family: FontAwesome;
    display: inline-block;
    margin-left: 0;
    width: 1.3em;
  }
}

.right {
  text-align: right;
}
