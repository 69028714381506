@import '../app.scss';

.social-contact {
  .social-field {
    width: 50%;
    display: inline-flex;
    box-sizing: border-box;
    padding: 15px;
    @include tablette {
      width: 100%;
    }

    .social-icon {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      border: solid 4px $primary-color;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      margin-right: 25px;
      transition: ease-in-out 0.2s;
      @include mobile {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      &:hover {
        background: $primary-color;
        & i {
          color: #fff;
        }
      }

      > i {
        font-size: 30px;
        justify-self: center;
        color: $tertiary-color;
        @include mobile {
          font-size: 18px;
        }
      }
    }

    .social-name {
      font-size: 18px;
      margin-top: 15px;
      @include mobile {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
}
