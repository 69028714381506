@import '../app.scss';

footer {
	section.t {
		padding: 15px;
	}
	p {
		text-align: center;
	}
	span {
		font-size: 15px;
		word-break: break-all;
		@include mobile {
			font-size: 12px;
		}
	}
	i {
		color: #fff;
	}
}
