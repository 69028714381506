@import '../app.scss';

header {
  padding-top: 30px;
  padding-bottom: 30px;

  @include tablette {
    position: fixed;
    width: 100%;
    z-index: 8;
    top: 0;
  }

  .content {
    display: flex;
    justify-content: space-between;

    > h1 {
      font-size: 20px;
      font-weight: normal;
      padding: 0;
      margin: 0;
      @include mobile {
        font-size: 15px;
      }
    }

    #nav-icon4 {
      display: none;
      @include tablette {
        display: block;
      }
    }
    > nav {
      margin-top: 5px;
      position: relative;
      z-index: 8;
      @include tablette {
        position: fixed;
        top: 0;
        left: 100%;
        bottom: 0;
        right: 0;
        background-color: #fff;
        margin: 0;
        transition: ease-in-out 0.5s;
      }

      &.open {
        left: 0;
      }
      > ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        @include tablette {
          display: block;
          text-align: center;
          margin-top: 150px;
        }
        @include mobile {
          margin-top: 100px;
        }
        li {
          margin: 0;
          padding: 0;
          margin-left: 40px;
          cursor: pointer;
          @include tablette {
            margin-bottom: 30px;
            margin-left: 0;
          }
          & > a:hover {
            color: $secondary-color;
          }
          > a {
            color: #fff;
            transition: ease-in-out 0.2s;
            @include tablette {
              color: $tertiary-color;
            }
          }
        }
      }
    }
  }
}

/* Icon 4 */

#nav-icon4 {
  width: 45px;
  height: 35px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 9;
}

#nav-icon4.open span {
  background: $primary-color;
}
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 13px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 26px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 29px;
  left: 8px;
}
