@import '../app.scss';
.carousel-item-active {
  width: 100% !important;
}
#temoignages {
  h2 {
    text-align: center;
  }
  .testimonial-field {
    width: 70%;
    margin: 0 auto;
    @include mobile {
      width: 100%;
    }

    .testimonial-header {
      > h3 {
        display: inline-block;
        padding: 0;
        margin: 0;
        margin-right: 25px;
        @include mobile {
          display: block;
          font-size: 16px;
        }
      }
      i {
        margin-right: 5px;
        color: $secondary-color;
        @include mobile {
          font-size: 16px;
        }
      }
    }

    .testimonial-body {
      margin-top: 15px;
      min-height: 100%;

      > p {
        padding: 0;
        margin: 0;
        line-height: 30px;
        min-height: 100%;
        overflow: auto;
        @include mobile {
          width: 100%;
        }
      }
    }

    li {
      width: auto;
      height: auto;

      button {
        width: 10px;
        height: 10px;
        background: #cecece;
        border-radius: 100%;
        cursor: pointer;
        transition: ease-in-out 0.2s;

        &::before {
          display: none;
        }
      }

      &.carousel-dots-active {
        button {
          background: #8c8c8c;
        }
      }
    }
  }
}
